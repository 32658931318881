
import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import {
    detailSmall,
    heading50,
    heading100,
    heading150,
    heading200,
    heading300,
    heading500,
} from "../stitches/combinedStyles";
import { styled } from "../stitches/stitches.config";
import DesktopImage from "./../images/products/desktop.png";
import Button from "../components/shared/Button/Button"

const TankYouPage = () => {
    const title = `Thank you for\nsubmitting your details.`;
    const caseStudiesPath = `/case-studies`;

    return (
        <>
            <Container>
                <Title>{title}</Title>
            </Container>
            <Description>
                <Titleheading150>Have you read our case studies?</Titleheading150>
                <Link to={caseStudiesPath}>
                    <Button size="large" tier="primary">
                        Case studies
                    </Button>
                </Link>
            </Description>
            <ImgDesktopImageWrapper>
                <ImgDesktopImage src={DesktopImage} alt="desktop" />
            </ImgDesktopImageWrapper>
        </>
    );
};

export default TankYouPage;

const ImgDesktopImageWrapper = styled("div", {
    width: "100%",
    textAlign: "center",
});

const ImgDesktopImage = styled("img", {
    height: "auto",
    margin: "0 auto",
    width: "100%",
    marginTop: "-220px",
    "@lg": {
        width: "100%",
        height: "auto",
        maxWidth: "1024px",
        marginTop: "-300px",
    },
});

const Container = styled("div", {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    backgroundColor: "$blue100",
    paddingBottom: 76,
    paddingTop: 76,
    "@lg": {
        width: "100%",
        maxWidth: "none",
        backgroundColor: "$blue100",
        paddingBottom: 200,
        paddingTop: 157,
        position: "relative",
    },
});

const Title = styled("h1", {
    ...heading200,
    margin: 0,
    color: "$white",
    whiteSpace: "pre-line",
    "@lg": {
        ...heading500,
        color: "$white",
        whiteSpace: "pre-line",
    },
});

const Titleheading150 = styled("h1", {
    ...heading150,
    color: "#5A7184",
    margin: "10px 60px",
    "@lg": {
        ...heading150,
    },
});

const Description = styled("div", {
    width: "90%",
    margin: "auto",
    maxWidth: 1140,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: "16px",
    background: "var(--white, linear-gradient(0deg, #FFF 0%, #FFF 100%), #1565D8)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    paddingBottom: 42,
    paddingTop: 42,
    marginTop: -20,
    "@lg": {
        width: "100%",
        maxWidth: 1140,

        textAlign: "center",
        marginTop: -80,
        position: "relative",
    },
});

const Content = styled("div", {
    backgroundColor: "white",

});
